import {Http} from "../../infrastructure/http"

const http = new Http()

export const handleFindFlight = (airportOrigin, airportReturn, passengers, departureDate, retDate, route, callback) => {

    return new Promise((resolve, reject) => {
        http.get(
            "public/externalFlights",
            {
                route: route,
                from: airportOrigin,
                to: airportReturn,
                departureDate: formatDate(departureDate),
                returnDate: retDate ? formatDate(retDate) : null,
                adults: passengers,
                class: "ECONOMY"
            },
            (err, res) => {
                err
                    ? reject(err)
                    : resolve({
                        state: "success",
                        data: res.data
                    })
            }
        )
    });
}

function formatDate(date) {

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const paddedMonth = month < 10 ? `0${month}` : month;
    const paddedDay = day < 10 ? `0${day}` : day;

    return `${year}-${paddedMonth}-${paddedDay}`;
}
