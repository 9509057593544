import React, {useEffect} from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import Container from 'common/components/UI/Container';
import CounterArea, {Row, Col} from './counter.style';

const Counter = () => {
    const Data = useStaticQuery(graphql`
    query {
      appMinimalJson {
        CounterData {
          blockTitle {
            title
            tagline
          }
          countBox {
            number
            text
          }
        }
      }
    }
  `);
    const {blockTitle, countBox} = Data.appMinimalJson.CounterData;
    const {title, tagline} = blockTitle;

    useEffect(() => {
        window.addEventListener('scroll', function () {
            const cloud = document.querySelector('.cloud-right');
            const cloudLeft = document.querySelector('.cloud-left');
            if(!cloud){
                return;
            }
            if(!cloudLeft){
                return;
            }
            let scrollPosition = window.scrollY;
            cloud.style.transform = 'translateY(' + scrollPosition * -0.1 + 'px)';
            cloudLeft.style.transform = 'translateY(' + scrollPosition * 0.2 + 'px)';
        });
    }, []);
    return (
        <CounterArea>
            <div className="cloud-right"></div>
            <div className="cloud-left"></div>
            <Container className="Container">
                <div style={{maxWidth: "600px", textAlign: "center", margin: "auto"}}>
                    <h4 style={{margin: 0, fontSize: '14px', padding: 0, color: '#2678DD'}}>BENEFITS</h4>
                    <h3 style={{fontSize: '40px', marginTop: '10px', marginBottom: 0, padding: 0}}>
                        What You Will Get
                    </h3>
                    <p style={{marginTop: '10px', padding: 0, fontWeight: '300', fontSize: "16px", marginBottom:"20px"}}>
                        You will receive a verifiable PDF dummy ticket with your name and PNR number. To verify, visit
                        the
                        airline’s “Manage Booking” or “My Trip” section, enter the reservation code and your surname.
                    </p>
                </div>
                <div style={{
                    padding: "20px",
                    margin: "auto",
                    marginTop: "30px",
                    marginBottom: "30px",
                    fontWeight: 500,
                    background: "white",
                    borderRadius: "20px",
                    border: "1px solid #D3E4F8",
                    maxWidth: "600px",


                }}>
                    This ticket serves as proof of travel plans needed for visas, border crossings, company HR/leave
                    requests, and exit visa procedures in many GCC countries
                </div>
                <div style={{width: "100%", margin: "auto",marginTop: "30px",marginBottom: "50px", display: "flex", flexWrap: "wrap", justifyContent: "space-between", position: "relative", zIndex: "1000"}}>
                    <div className="boxes">
                        <img src="/img/email.png" width="70px"/>
                        <h3>Receive Ticket via Email</h3>
                        <p>Tickets will be delivered in your email box</p>
                    </div>
                    <div className="boxes">
                        <img src="/img/truck.png" width="70px"/>
                        <h3>Customize tickets delivery</h3>
                        <p>You can choose any date you want for the ticket delivery.</p>
                    </div>
                </div>
            </Container>
        </CounterArea>
    );
};

export default Counter;
