import {Http} from "../../infrastructure/http"
import axios from 'axios';
const http = new Http()

const apiUrl = process.env.API_HOST
const apiVersion = process.env.API_VERSION
let cancelTokenSource;

export const handleFindAirports = (query) => {

    if (cancelTokenSource) {
        cancelTokenSource.cancel('Operation canceled due to new request.');
    }

    cancelTokenSource = axios.CancelToken.source();
    let endpoint = "public/airports";

    return new Promise((resolve, reject) => {
        axios.get(`${apiUrl}/${apiVersion}/${endpoint}`, {
            params: { q: query },
            cancelToken: cancelTokenSource.token
        }).then(res => {
            resolve({
                statusCode: res.status,
                data: res.data
            });
        }).catch(err => {
            if (axios.isCancel(err)) {
                console.log('request aborted');
            } else {
                console.log('Error:', err.message);
                if (err.response && err.response.status > 299) {
                    reject(err.response.data.error);
                    return;
                }
                reject(err);
            }
        });
    });
}
