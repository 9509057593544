import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/GatsbyImage';
import FeatureBlock from 'common/components/FeatureBlock';
import { SectionHeader } from '../app-minimal.style';
import SectionWrapper, { ThumbWrapper, TextWrapper } from './choose-us.style';

const ChooseUs = () => {
  const Data = useStaticQuery(graphql`
    query {
      appMinimalJson {
        chooseUs {
          title
          description
          features {
            id
            title
            description
          }
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                width: 724
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `);
  const { title, description, thumbnail, features } =
    Data.appMinimalJson.chooseUs;
  return (
    <SectionWrapper id="choose_section">
      <Container>
        <div style={{position: "relative", zIndex: "999", maxWidth: "50%"}} className="reasonsTitle">
          <h4 style={{margin: 0, fontSize: '14px', padding: 0, color: '#2678DD'}}>WHY US?</h4>
          <h3 style={{fontSize: '40px', marginTop: '10px', marginBottom: 0, padding: 0}}>
            Reasons To Choose Us
          </h3>
          <p style={{marginTop: '10px', padding: 0, fontWeight: '300'}}>
            Reliable, Fast, and Risk-Free Travel Solutions for Hassle-Free Reservations
          </p>
        </div>
        <div style={{position: "relative", zIndex: "999", display: "flex", flexWrap:"wrap"}}>
          <div className="reasons">
            <img src="/img/rayo.png" width="70px"/>
            <h3>Fast Service</h3>
            <p>Secure flight reservations for any destination or country, and receive your tickets within 24 hours via email</p>
          </div>
          <div className="reasons">
            <img src="/img/secure.png" width="70px"/>
            <h3>Guaranteed Validity</h3>
            <p>Valid for a minimum of 48 hours and can be extended further. No need for later cancellations</p>
          </div>
          <div className="reasons">
            <img src="/img/money.png" width="70px"/>
            <h3>Money Back Guarantee</h3>
            <p>If the ticket doesn’t meet your needs, just email us with evidence, and we’ll refund your order</p>
          </div>
        </div>
      </Container>
    </SectionWrapper>
  );
};

export default ChooseUs;
