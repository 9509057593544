import styled from 'styled-components';
import Cloud10 from 'common/assets/image/app-minimal/v2/cloud-right.png';
import Cloud11 from 'common/assets/image/app-minimal/v2/cloud-left.png';

const CounterArea = styled.section`
    text-align: center;
    padding-bottom: 30px;
    padding-top: 80px;
    background: #E9F1FC;

    .boxes {
        background: white;
        border: 1px solid #D3E4F8;
        padding: 60px 30px 60px 30px;
        width: 49.5%;
        border-radius: 25px;
        box-shadow: 0px 1px 4px 0px #110C521F;
        margin-bottom: 50px;

    }
    .boxes h3{
        font-size: 26px;
    }

    .Container {
        max-width: 1000px;
        position: relative;
        z-index: 999;
    }

    .cloud-right {
        background-image: url(${Cloud10});
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right top;
        position: absolute;
        z-index: 100;
        width: 100%;
        height: 100%;
        top: -50px
    }

    .cloud-left {
        background-image: url(${Cloud11});
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left bottom;
        position: absolute;
        z-index: 100;
        width: 100%;
        height: 100%;
        top: -130px
    }
    @media (max-width: 800px) {
        padding-top: 0;
        .boxes{
            width: 100%;
            margin-bottom: 20px;
        }
    }

    .topTitle {
        margin-bottom: 85px;
        @media (max-width: 575px) {
            margin-bottom: 20px;
        }

        h2 {
            font-weight: bold;
            font-size: 30px;
            line-height: 1.3;
            text-align: center;
            letter-spacing: -0.02em;
            color: #0f2137;
            width: 100%;
            max-width: 690px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 15px;
            @media (max-width: 1600px) {
                font-size: 30px;
                max-width: 546px;
            }
            @media (max-width: 575px) {
                font-size: 22px;
                max-width: 100%;
            }
        }

        p {
            font-weight: normal;
            font-size: 17px;
            line-height: 2.06;
            letter-spacing: 0.1px;
            color: #0f2137;
            max-width: 750px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0;
            @media (max-width: 1600px) {
                font-size: 15px;
            }
        }
    }

    .CounterBox {
        h3 {
            font-weight: normal;
            font-size: 30px;
            line-height: 1;
            text-align: center;
            letter-spacing: -0.02em;
            color: rgb(38, 120, 221);
            margin-bottom: 0;

            &::before {
                content: '+';
                font-size: 40px;
                display: inline-block;
                vertical-align: top;
                position: relative;
                top: -10px;
                margin-right: 7px;
            }
        }

        p {
            font-weight: normal;
            font-size: 17px;
            text-align: center;
            color: #09131f;
            margin-top: 15px;
            margin-bottom: 10px;
        }
    }

    .counterLink {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #1089ff;
    }
`;
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
`;
export const Col = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 60px;
  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 25px;
  }
`;

export default CounterArea;
