import styled from 'styled-components';
import Plane from 'common/assets/image/app-minimal/v2/plane3.png';
import Clouds from 'common/assets/image/app-minimal/v2/clouds2.png';
import Cloud10 from 'common/assets/image/app-minimal/v2/cloud11.png';

const FlightFormArea = styled.section`
    margin-top: 50px;
    background: linear-gradient(180deg, #FFFFFF 0%, #CFDFF6 108.63%);
    padding-bottom: 50px;

    .clearfix:after {
        content: "";
        clear: both;
        display: table;
    }

    svg {
        max-width: 20px;
    }

    .container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .plane-obj {
        background-image: url(${Plane});
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left bottom;
        position: absolute;
        width: 50%;
        max-width: 70%;
        height: 100%;
        z-index: 99;
        left: -100px;
        transition: transform 0.1s ease-out;
    }

    .walterWhite {
        width: 100%;
        height: 30px;
        background: white;
        position: absolute;
        z-index: 101;
        bottom: 0;
        left: 0;
    }

    .clouds {
        background-image: url(${Clouds});
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left 104%;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .cloud10 {
        background-image: url(${Cloud10});
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left 126%;
        position: absolute;
        z-index: 100;
        width: 50%;
        height: 100%;
        opacity: 0.8;
    }
    

    
    h3 {
        margin: 0;
        padding: 0;
        font-size: 26px;
        margin-bottom: 20px;
    }

    .formIn {
        flex: 1;
        width: 50%;
        position: relative;
        z-index: 101;
    }

    .form-block {
        background: white;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px;
        padding: 30px;
        box-shadow: 0px 1px 4px 0px rgba(15, 12, 56, 0.12);
        max-width: 550px;

    }

    .titles {
        padding-right: 60px;
    }

    .title {
        font-size: 55px;
        padding: 0;
        margin: 0;
    }


    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .react-datepicker__input-container input {
        width: 100%; /* Ajusta el ancho del input si es necesario */
        font-size: 16px; /* Ajusta el tamaño de la fuente del input */
    }

    .react-datepicker__tab-loop {
        position: absolute;
        top: 0;
        left: 0;
    }

    .react-datepicker-wrapper {
    }

    .react-datepicker__month-container {
        width: 100%;
    }

    .react-datepicker {
        font-family: 'DM Sans', sans-serif; /* Cambia la fuente si es necesario */
        border: 1px solid #ccc;
        box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
        font-size: 16px; /* Aumenta el tamaño de la fuente dentro del datepicker */
        width: 350px; /* Aumenta el ancho del datepicker */
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 40px; /* Aumenta el tamaño de las celdas del calendario */
        height: 40px; /* Aumenta el tamaño de las celdas del calendario */
        line-height: 40px; /* Centra el texto verticalmente */
    }

    .react-datepicker__header {
        /*background-color: rgb(38, 120, 221);*/
        background-color: white;
        color: black;
    }

    .react-datepicker__current-month {
        font-weight: bold;
        color: black;
    }

    .react-datepicker__day:hover {
        background: #6989b0;
        color: white;
    }

    .react-datepicker__day, .react-datepicker__time-name {
        color: #333;
    }

    .react-datepicker__day-names {
        color: black;
    }

    .react-datepicker__day-name {
        color: black;
        font-weight: bold;
    }

    .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
        background-color: #0d6efd;
        color: white;
    }

    .react-datepicker__day--disabled {
        color: #ccc;
    }

    .react-datepicker__close-icon {
        background-color: transparent;
        color: #999;
        top: 0.5rem;
        right: 0.5rem;
    }

    .react-datepicker__current-month {
        font-weight: bold;
        color: #2678dd;
    }

    .input {
        padding: 10px;
        margin-right: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        border: 1px solid rgba(226, 232, 240, 1);
        font-family: 'DM Sans', sans-serif;
        width: 100%;
        text-align: left;
        background: white;
        font-size: 16px;
        cursor: text;
        color: rgba(100, 116, 139, 1);
    }

    .input svg {
        margin-right: 10px;
    }

    select {
        cursor: pointer !important;
    }

    .input:focus {
        border: 1px solid rgb(38, 120, 221);
    }

    .input-container {
        width: 100%;
        margin-bottom: 20px;
    }

    .input-container:last-child {
        margin-right: 0;
    }


    .inputLabel {
        width: 100%;
        margin-bottom: 10px;
        font-size: 16px;
        color: rgba(100, 116, 139, 1);

    }

    .input-container select {
        padding: 9px;
        max-width: 100px;
    }

    .frow {
        width: 100%;
        float: left;
    }

    /* Estilos personalizados para checkboxes grandes y redondos */

    .custom-checkbox input:checked ~ label::before {
        background-color: #0d6efd; /* Color de fondo cuando está seleccionado */
    }

    .custom-checkbox {
        width: 1.8em; /* Aumenta el ancho del checkbox */
        height: 1.8em; /* Aumenta la altura del checkbox */
        cursor: pointer;
        border: 1px solid rgba(226, 232, 240, 1);
    }

    .custom-checkbox label::before {
        border-radius: 50%;
        border: 0.1em solid rgba(226, 232, 240, 1);

    }


    .custom-checkbox label::before {
        content: '';
        display: inline-block;
        width: 1.8em;
        height: 1.8em;
        border: 1px solid rgba(226, 232, 240, 1); /* Cambiado a 1px */
        border-radius: 50%; /* Checkbox redondo */
        background-color: transparent;
        vertical-align: middle;
        margin-right: 5px;
        transition: all 0.3s ease; /* Animación suave para el cambio de estado */
    }

    .custom-checkbox .label::after {
        top: calc(0.75em - 0.5em); /* Centra la marca de verificación verticalmente */
        left: calc(0.75em - 0.5em); /* Centra la marca de verificación horizontalmente */
        width: 0.5em;
        height: 1em;
    }

    .checkbox-control {
        float: left;
        margin-right: 20px;
        display: flex;
        align-items: center; /* This ensures vertical center alignment */
        cursor: pointer;
    }

    .checkbox-control label {
        font-size: 16px;
        line-height: 50px;
        margin-left: 5px;
        color: rgba(100, 116, 139, 1);
    }

    .checkbox-control label::before, label::after {
        top: 50%;
        transform: translateY(-50%); /* Centers the pseudo-elements vertically */
        width: 1.5em;
        height: 1.5em;
    }

    .input-container ul {
        box-sizing: border-box;
        width: 100%;
        /*max-width: 500px;*/
        margin: 0;
        padding: 0;
        list-style: none;
        border: 1px solid #ccc;
        border-top: none; /* Remove the top border to merge input and list */
        background-color: white;
        border-radius: 0 0 4px 4px;
        max-height: 200px;
        overflow-y: auto; /* Makes the dropdown scrollable */
    }

    .input-container li {
        padding: 8px;
        cursor: pointer;
        color: rgba(100, 116, 139, 1)
    }

    .input-container li:hover, li:focus {
        background-color: #f0f0f0;
    }

    .form-outline {
        position: relative;
    }

    .form-outline svg {
        position: absolute;
        top: 50%;
        transform: translateY(50%);
        pointer-events: none;
    }

    .alert {
        width: 100%;
        margin-top: 30px;
        padding: 10px;
        border-radius: 5px;

    }

    .alert-error {
        background-color: #f8819b;
        border: 1px solid red;
        color: black;
    }

    .alert-warn {
        background-color: #f8819b;
        border: 1px solid #e3c138;
        color: black;
    }

    .alert-info {
        background-color: #cccc5a;
        border: 1px solid #c5c530;
        color: black;
    }

    .space {
        min-height: 350px;
    }

    @media only screen and (max-width: 1200px) {
        .input-container {
            width: 100%;
        }

        .space {
            min-height: 130px;
        }
    }
    @media only screen and (max-width: 1600px) {
        .input-container {
            width: 100%;
        }

        .space {
            min-height: 280px;
        }
    }
    @media only screen and (max-width: 1100px) {
        .formIn .title {
            font-size: 45px !important;
        }
    }
    @media only screen and (max-width: 900px) {
        .formIn .title {
            font-size: 40px !important;
        }

        .rightColumn p {
            font-size: 12px !important;
        }
    }
    @media only screen and (max-width: 800px) {
        .titles {
            padding-right: 0;
            text-align: center;
        }

        .formIn {
            width: 100%;
            flex: 1 1 100%;
        }

        .space {

            min-height: 150px !important;
        }

        .form-block {
            max-width: 100%;
        }

        .plane-obj {
            background-position: left 35%;
            width: 100%;
            max-width: 100%;
        }
        
    }
    @media only screen and (max-width: 500px) {
        .space {

            min-height: 100px !important;
        }
        .formIn .title {
            font-size: 35px !important;
        }
        .plane-obj {
            background-position: left 31%;
            width: 100%;
            max-width: 100%;
        }
    }
}




`;


export default FlightFormArea;
