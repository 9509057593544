import React from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/GatsbyImage';
import SectionWrapper, {ContentWrapper} from './secure-transaction.style';

const SecureTransaction = () => {
    const Data = useStaticQuery(graphql`
    query {
      appMinimalJson {
        secureTransaction {
          title
          description
          image {
            thumb {
              childImageSharp {
                gatsbyImageData(
                  width: 700
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            bubble {
              publicURL
            }
          }
        }
      }
    }
  `);
    const {image, title, description} = Data.appMinimalJson.secureTransaction;

    return (
        <SectionWrapper>
            <Container>
                <ContentWrapper className="faws">
                    <div style={{maxWidth: "600px", textAlign: "center", margin: "auto"}}>
                        <h4 style={{margin: 0, fontSize: '14px', padding: 0, color: '#2678DD'}}>FAQs</h4>
                        <h3 style={{fontSize: '40px', marginTop: '10px', marginBottom: 0, padding: 0}}>
                            Frequently Ask Question
                        </h3>
                    </div>
                    <section id="faq_section"
                             style={{width: "80%", margin: "auto", textAlign: "justify", paddingTop: "50px"}}>
                        <div>
                            <h3>What are temporary flight tickets?</h3>
                            <p>Temporary flight tickets are non-booked flight reservations used for visa applications or
                                proof of travel. They are not actual flight tickets but provide the necessary
                                documentation
                                for visa applications and other travel requirements.</p>
                        </div>
                        <div>
                            <h3>Are temporary flight tickets accepted for visa
                                applications? </h3>
                            <p>Yes, temporary flight tickets are commonly accepted by embassies and consulates for visa
                                applications as proof of travel intent.</p>
                        </div>
                        <div>
                            <h3>How long is the temporary flight ticket valid?</h3>
                            <p> Temporary flight tickets are usually valid for 48-72 hours. They are meant for
                                short-term
                                use to support your visa application or travel plans.</p>
                        </div>
                        <div>
                            <h3>Is it safe to use temporary flight tickets for visa
                                applications? </h3>
                            <p>Yes, it is safe and legal to use temporary flight tickets for visa applications as long
                                as
                                they meet the requirements of the embassy or consulate. Our service ensures that your
                                temporary ticket appears as a legitimate reservation to fulfill your travel
                                documentation
                                needs.</p>
                        </div>
                    </section>
                </ContentWrapper>
            </Container>
        </SectionWrapper>
    );
};

export default SecureTransaction;
