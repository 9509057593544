import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const SectionWrapper = styled.div`
  padding-bottom: 60px;
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 50px;

    #faq_section > div {
        border: 1px solid #E2E8F0;
        border-radius: 15px;
        padding: 20px;
        margin-bottom: 20px;
    }
    #faq_section h3 {
        padding-top: 0;
        margin-top: 0;
        font-size: 22px;
    }

    #dashboard-img img {
        width: 100% !important;
        -webkit-box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.36);
        -moz-box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.36);
        box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.36);
    }

    .image {
        position: relative;
        width: calc(100% - 465px);
        @keyframes bubbleLeftRight {
            0% {
                transform: translateX(0px);
            }
            25% {
                transform: translateX(5px);
            }
            50% {
                transform: translateX(15px);
            }
            75% {
                transform: translateX(5px);
            }
            100% {
                transform: translateX(0px);
            }
        }

        img.bubble-image {
            position: absolute;
            bottom: 120px;
            animation: bubbleLeftRight 10s linear infinite;
            right: 0px;
            @media (max-width: 1600px) {
                right: -90px;
            }
            @media (max-width: 991px) {
                display: none;
            }
        }

        @media (max-width: 1600px) {
            width: calc(100% - 570px);
        }
        @media only screen and (max-width: 991px) {
            margin-bottom: 40px;
            width: calc(100%);
            img {
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }

        @media only screen and (max-width: 667px) {
            width: 100%;
        }
    }

    .content {
        width: 465px;
        @media (max-width: 1600px) {
            margin-left: 100px;
        }
        @media only screen and (max-width: 991px) {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
        }
        @media only screen and (max-width: 667px) {
            width: 100%;
        }

        h2 {
            color: ${themeGet('colors.headingColor', '#09131F')};
            font-size: 36px;
            line-height: 1.35;
            margin-bottom: 20px;
            letter-spacing: -0.5px;
            font-weight: 500;
            @media (max-width: 1600px) {
                font-size: 32px;
            }
            @media (max-width: 575px) {
                font-size: 22px;
            }
        }

        p {
            font-size: 16px;
            font-weight: normal;
            line-height: 2;
            margin-bottom: 35px;
            color: ${themeGet('colors.textColor', '#343D48')};
            @media only screen and (max-width: 1600px) {
                margin-bottom: 30px;
            }
        }

        li {
            height: 50px;
            margin-bottom: 10px;
            line-height: 20px;
            text-align: center;
        }

        li::before {
            content: "+";
            color: #1089ff;
            font-size: 40px;
            display: inline-block;
            vertical-align: top;
            position: relative;
            margin-right: 7px;
            top: 15px;
        }

        td {
            color: #1089ff;
        }
    }

    .button {
        display: inline-block;
        background-color: #23374d;
        color: #fff;
        font-size: 15px;
        font-weight: bold;
        padding: 20px 35px;
        border-radius: 30px;
        transition: all 500ms ease;

        &:hover {
            background-color: #108aff;
        }

        > i {
            margin-left: 5px;
        }

        @media (max-width: 1600px) {
            font-size: 14px;
        }
    }
`;

export default SectionWrapper;
