import React, {useState, useEffect, useRef} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Container from 'common/components/UI/Container';
import SectionWrapper from './key-features.style';

const KeyFeatures = () => {
    const Data = useStaticQuery(graphql`
        query {
            appMinimalJson {
                keyFeatures {
                    title
                    description
                    features {
                        id
                        icon {
                            publicURL
                        }
                        title
                        description
                    }
                }
            }
        }
    `);

    const [currentStep, setCurrentStep] = useState(0);
    const steps = 3; // Número de pasos
    const stepHeight = 215; // Altura de cada paso
    const scrollThreshold = 600; // Aumentar umbral de desplazamiento
    const stepContainerRef = useRef(null); // Referencia al contenedor
    const [lastScrollTop, setLastScrollTop] = useState(0); // Estado para controlar la última posición de scroll

    // Función para comprobar si el contenedor de los pasos está visible
    const isContainerInView = () => {
        const rect = stepContainerRef.current.getBoundingClientRect();
        return rect.top >= 0 && rect.bottom <= window.innerHeight;
    };
    useEffect(() => {
        let previousTranslateY = 0; // Para mantener el valor previo de translateY

        const handleScroll = () => {
            const rightColumn = document.querySelector('.rightColumn');
            const rect = rightColumn.getBoundingClientRect();
            const positionY = rect.top + window.scrollY;

            let scrollPosition = window.scrollY;

            const pos = scrollPosition - 1000;
            const secondPos = positionY - 200;

            // Primer tramo: Parallax más suave (mover hacia abajo)
            if (scrollPosition >= (positionY - 500) && scrollPosition < secondPos) {
                const translateYValue = pos * 0.2;
                rightColumn.style.transform = `translateY(${translateYValue}px)`;
                previousTranslateY = translateYValue; // Guardamos el valor actual de translateY
            }

            // Segundo tramo: Continuar el desplazamiento hacia arriba (dirección negativa)
            if (scrollPosition >= secondPos) {
                // Calculamos el incremento en translateY, manteniendo la continuidad
                const deltaY = scrollPosition - secondPos; // Diferencia desde el segundo umbral
                const newTranslateY = previousTranslateY - deltaY * 0.15; // Mover hacia arriba con factor negativo
                rightColumn.style.transform = `translateY(${newTranslateY}px)`;
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    // Manejo del scroll
    // useEffect(() => {
    //     let isThrottled = false;
    //     let stepsCompleted = false;
    //
    //     const handleScroll = () => {
    //         if (isThrottled) return;
    //         isThrottled = true;
    //
    //         setTimeout(() => {
    //             const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    //
    //             if (isContainerInView() && !stepsCompleted) {
    //                 // Detección de la dirección del scroll y aplicando el umbral
    //                 if (scrollTop - lastScrollTop > scrollThreshold && currentStep < steps - 1) {
    //                     // Desplazarse hacia abajo, avanza al siguiente paso
    //                     setCurrentStep((prevStep) => prevStep + 1);
    //                 } else if (lastScrollTop - scrollTop > scrollThreshold && currentStep > 0) {
    //                     // Desplazarse hacia arriba, retrocede al paso anterior
    //                     setCurrentStep((prevStep) => prevStep - 1);
    //                 }
    //
    //                 // Si todos los pasos han sido mostrados, permitimos el scroll normal
    //                 if (currentStep === steps - 1) {
    //                     stepsCompleted = true;
    //                 }
    //
    //                 // Actualiza la última posición de scroll
    //                 setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
    //             }
    //
    //             isThrottled = false;
    //         }, 300); // Límite de tiempo para evitar la activación repetitiva
    //     };
    //
    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [currentStep, lastScrollTop]);

    const containerStyle = {
        display: 'flex',
        flexWrap: 'wrap',
    };

    const leftBoxStyle = {
        textAlign: 'left',
        width: '45%',
        paddingRight: '5%',
        flex: 1,
    };

    const sliderContainerStyle = {
        width: '45%',
        marginLeft: '5%',
        flex: 1,

        color: 'white',
        borderRadius: '15px',
        marginTop: '25px',
    };

    const sliderContentStyle = {
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 2s ease-out',
        transform: `translateY(-${stepHeight * currentStep}px)`,
    };

    const stepStyle = {
        height: `${stepHeight}px`,
        padding: '30px',
        background: '#2678DD',
        marginBottom: "10px",
        borderRadius: '15px',
    };

    return (
        <SectionWrapper id="feature_section">
            <Container>
                <div ref={stepContainerRef} style={containerStyle}>
                    {/* Columna izquierda: contenido estático */}
                    <div style={leftBoxStyle} className="leftColumn">
                        <h4 style={{margin: 0, fontSize: '14px', padding: 0, color: '#2678DD'}}>PROCESS</h4>
                        <h3 style={{fontSize: '40px', marginTop: '10px', marginBottom: 0, padding: 0}}>
                            Book Your Reservation in Minutes
                        </h3>
                        <p style={{marginTop: '10px', padding: 0, fontWeight: '300'}}>
                            Effortlessly reserve your next event or gathering with just a few steps. Our platform
                            streamlines the process,
                            allowing you to select the time, date, and location with ease. You’ll receive instant
                            confirmation and peace of mind
                            knowing your spot is secured.
                        </p>
                    </div>

                    {/* Columna derecha: contenido dinámico con slider */}
                    <div style={sliderContainerStyle} className="rightColumn">
                        <div style={sliderContentStyle}>
                            {/* Step 1 */}
                            <div style={stepStyle} className="boxin">
                                <h4 style={{
                                    margin: 0,
                                    fontSize: '16px',
                                    padding: 0,
                                    color: 'white',
                                    marginBottom: '20px'
                                }}>Step 1</h4>
                                <h3 style={{fontSize: '25px', marginTop: '10px', marginBottom: 0, padding: 0}}>Search
                                    For Your Flight</h3>
                                <p style={{marginTop: '10px', padding: 0, fontWeight: '300'}}>
                                    Fill out the form with your departure, destination, and travel dates. Our system
                                    will quickly find the best flights
                                    available, giving you convenient options in seconds.
                                </p>
                            </div>

                            {/* Step 2 */}
                            <div style={stepStyle} className="boxin">
                                <h4 style={{
                                    margin: 0,
                                    fontSize: '16px',
                                    padding: 0,
                                    color: 'white',
                                    marginBottom: '20px'
                                }}>Step 2</h4>
                                <h3 style={{fontSize: '25px', marginTop: '10px', marginBottom: 0, padding: 0}}>Confirm and Pay</h3>
                                <p style={{marginTop: '10px', padding: 0, fontWeight: '300'}}>
                                    Select the delivery that suit your preferences. Review your booking details and make the payment
                                </p>
                            </div>

                            {/* Step 3 */}
                            <div style={stepStyle} className="boxin">
                                <h4 style={{
                                    margin: 0,
                                    fontSize: '16px',
                                    padding: 0,
                                    color: 'white',
                                    marginBottom: '20px'
                                }}>Step 3</h4>
                                <h3 style={{fontSize: '25px', marginTop: '10px', marginBottom: 0, padding: 0}}>Download your ticket</h3>
                                <p style={{marginTop: '10px', padding: 0, fontWeight: '300'}}>
                                    Receive your ticket in your email within the next 24 hours
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </SectionWrapper>
    );
};

export default KeyFeatures;
