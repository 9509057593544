import React, {useEffect, useRef, useState} from "react";
import FlightFormArea from "./flightForm.style";
import Container from 'common/components/UI/Container';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faPlane} from '@fortawesome/free-solid-svg-icons';
import {handleFindAirports} from "../../../usecases/airport/findAirport";
import {handleFindFlight} from "../../../usecases/flights/findFlight";
import {navigate} from "gatsby";
import {useCookies} from "react-cookie";
import * as moment from "moment";

const CustomDatePickerInput = React.forwardRef(({value, onClick}, ref) => (
    <button className="input react-datepicker-ignore-onclickoutside" onClick={onClick} ref={ref}>
        <FontAwesomeIcon icon={faCalendarAlt}/>
        {value}
    </button>
));

const FlightForm = (props) => {
    const [passengers, setPassengers] = useState(1);
    const [returnDate, setReturnDate] = useState(new Date());
    let startDateRa = moment(new Date());
    startDateRa.add(2, "days");
    const [departureDate, setDepartureDate] = useState(startDateRa.toDate());
    const [startDate, setStartDate] = useState(startDateRa.toDate());
    const [isChecked, setIsChecked] = useState({
        option1: true,
        option2: false
    });
    const [route, setRoute] = useState("");
    const [query, setQuery] = useState('');
    const [suggestionsOrigin, setSuggestionsOrigin] = useState([]);
    const [showSuggestionsOrigin, setShowSuggestionsOrigin] = useState(false);

    const [queryReturn, setQueryReturn] = useState('');
    const [suggestionsReturn, setSuggestionsReturn] = useState([]);
    const [showSuggestionsReturn, setShowSuggestionsReturn] = useState(false);

    const [airportOrigin, setAirportOrigin] = useState("");
    const [airportReturn, setAirportReturn] = useState("");

    const [originInvalid, setOriginInvalid] = useState(false);
    const [returnInvalid, setReturnInvalid] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isError, setIsError] = useState(false);
    const [noFlightsFound, setNoFlightsFound] = useState(false);
    const [referer, setReferer] = useState("");
    const [cookies, setCookie] = useCookies([]);

    const findAirport = async (query) => {
        let res = await handleFindAirports(query);
        return res.data;
    }

    const findFlight = async (airportOrigin, airportReturn, passengers, departureDate, retDate, route) => {
        setIsError(false);
        setNoFlightsFound(false);
        try {
            let res = await handleFindFlight(airportOrigin, airportReturn, passengers, departureDate, retDate, route);
            if (res.data.length === 0) {
                setNoFlightsFound(true);
                setIsSubmitting(false);
                return;
            }
            const state = {
                flight: res.data[0],
                route: route,
                referer: referer,
                from: airportOrigin,
                to: airportReturn,
                departureDate: departureDate,
                returnDate: retDate,
                passengers: passengers
            };

            setCookie("order", JSON.stringify(state), {path: "/"})
            await navigate("/checkout", {
                state: state
            });
        } catch (error) {
            setIsError(true)
            setIsSubmitting(false);
        }

    }

    const submitForm = () => {
        setIsSubmitting(true);
        setOriginInvalid(false);
        setReturnInvalid(false);
        if (query === "") {
            setOriginInvalid(true);
            setIsSubmitting(false);
            return;
        }
        if (queryReturn === "" || queryReturn == null) {
            setReturnInvalid(true);
            setIsSubmitting(false);
            return;
        }
        let retDate = null;
        let route = "ROUND_TRIP";
        if (isChecked.option1) {
            route = "ONE_WAY";
        }
        if (isChecked.option2) {
            route = "ROUND_TRIP";
            retDate = returnDate;
        }
        if (airportOrigin === "") {
            setOriginInvalid(true);
            setIsSubmitting(false);
            return;
        }
        if (airportReturn === "") {
            setReturnInvalid(true);
            setIsSubmitting(false);
            return;
        }
        findFlight(airportOrigin, airportReturn, passengers, departureDate, retDate, route)
    }

    const updateReturnDate = (date) => {
        setDepartureDate(date);
        const newReturnDate = moment(new Date(date));
        newReturnDate.add( 1, "days");
        setReturnDate(newReturnDate.toDate());
    }

    const handleRouteChange = (event) => {
        if (event.target.value == 1) {
            setIsChecked({
                option1: true,
                option2: false
            });
        }
        if (event.target.value == 2) {
            setIsChecked({
                option1: false,
                option2: true
            });
        }

    }

    const handleClickOutside = (event) => {
        if (event.target.className !== "airport-option") {
            setShowSuggestionsOrigin(false);
            setShowSuggestionsReturn(false);
        }
    };

    const handleSearchOrigin = async (event) => {
        const value = event.target.value;
        setQuery(value);
        if (value.length < 2) {
            return;
        }
        let airports = [];
        if (value.length > 0) {
            try {
                airports = await findAirport(value);
            } catch (error) {
                console.log(error);
            }
            setSuggestionsOrigin(airports);
            setShowSuggestionsOrigin(true);
        } else {
            setSuggestionsOrigin([]);
            setShowSuggestionsOrigin(false);
        }
    };

    const handleSearchReturn = async (event) => {
        const value = event.target.value;

        setQueryReturn(value);
        if (value.length < 2) {
            return;
        }
        let airports = [];
        if (value.length > 0) {
            try {
                airports = await findAirport(value);
            } catch (error) {
                console.log(error);
            }
            setSuggestionsReturn(airports);
            setShowSuggestionsReturn(true);
        } else {
            setSuggestionsReturn([]);
            setShowSuggestionsReturn(false);
        }
    };

    const handleSuggestionOriginClick = (value) => {
        setOriginInvalid(false);
        setQuery("(" + value.airportCode + ") " + value.name);
        setAirportOrigin(value.airportCode);
        setShowSuggestionsOrigin(false);
    };

    const handleSuggestionReturnClick = (value) => {
        setReturnInvalid(false);
        setQueryReturn("(" + value.airportCode + ") " + value.name);
        setAirportReturn(value.airportCode);
        setShowSuggestionsReturn(false);
    };

    useEffect(() => {
        document.querySelector('.formIn').classList.add('visible');
        const referer = document.referrer
        setReferer(referer);
        const newReturnDate = moment(new Date(startDate));
        newReturnDate.add(1, "days");
        setReturnDate(newReturnDate.toDate());
        document.addEventListener("mousedown", handleClickOutside);
        window.addEventListener('scroll', function() {
            const plane = document.querySelector('.plane-obj');
            if(!plane){
                return;
            }
            let scrollPosition = window.scrollY;
            plane.style.transform = 'translateX(' + scrollPosition * 0.1 + 'px) translateY(' + scrollPosition * -0.2 + 'px)';
        });

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [startDate]);

    return (
        <FlightFormArea id="flight_form_section">
            <div className="plane-obj"></div>
            <div className="cloud10"></div>
            <div className="clouds"></div>
            <div className="walterWhite"></div>
            <Container>
                <div className="clearfix formIn titles">
                    <h1 className="title">Book a Real and Verifiable Temporary Ticket for <span
                        style={{color: "#2678DD"}}>Only $12</span></h1>
                    <p>Get an authentic and verifiable temporary ticket, perfect for visa applications and proof of
                        return at the airport, with a valid PNR.</p>
                    <div className="space"></div>
                </div>
                <div className="clearfix formIn">
                    <div className="clearfix form-block">
                        <div className="clearfix frow">
                            <h3>Choose Destination</h3>
                            <div className="input-container" style={{width: "60%", float: "left"}}>
                                <div className="form-outline">
                                    <label className="inputLabel" htmlFor="route"
                                           style={{float: "left", marginBottom: "0px"}}>Choose Route</label>
                                    <select className="input" style={{width: "100%", maxWidth: "100%"}}
                                            onChange={handleRouteChange}>
                                        <option value="1">One Way</option>
                                        <option value="2">Round Trip (+$5)</option>
                                    </select>
                                </div>
                            </div>
                            <div className="input-container" style={{width: "38%", float: "left", marginLeft: "2%"}}>
                                <div className="form-outline">
                                    <label className="inputLabel" htmlFor="route"
                                           style={{float: "left", marginBottom: "0px", maxWidth: "100%"}}>Travelers</label>
                                    <select className="input" style={{width: "100%", maxWidth: "100%"}} onChange={val => setPassengers(val.target.value)}>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="clearfix frow">


                        </div>

                        <div className="clearfix frow">
                            <div className="input-container">
                                <div className="form-outline">
                                    <label className="inputLabel">From</label>
                                    <input
                                        type="text"
                                        value={query}
                                        onChange={handleSearchOrigin}
                                        className="input"
                                        onClick={() => setShowSuggestionsOrigin(true)}
                                        style={{paddingLeft: '35px', border: originInvalid ? '1px solid red' : ''}}
                                        placeholder="Search airport or city"
                                    />
                                    <FontAwesomeIcon icon={faPlane} style={{
                                        position: 'absolute',
                                        left: 10,
                                        top: '40%',
                                        transform: 'translateY(55%)',
                                        zIndex: 1,
                                        color: "rgba(100,116,139,1)"
                                    }}/>
                                </div>
                                {showSuggestionsOrigin && suggestionsOrigin.length > 0 && (
                                    <div className="clearfix" style={{position: 'relative'}}>
                                        <ul className="airport-option" style={{position: 'absolute', zIndex: 999999}}>
                                            {suggestionsOrigin.map((item, index) => (
                                                <li className="airport-option" key={index}
                                                    onClick={() => handleSuggestionOriginClick(item)}>
                                                    <img src={`/img/flags/${item.country.toLowerCase()}.svg`}
                                                         alt={item.country}
                                                         width="15px"/>&nbsp;
                                                    ({item.airportCode}) {item.name}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <div className="input-container">
                                <div className="form-outline">
                                    <label className="inputLabel">To</label>
                                    <input
                                        type="text"
                                        value={queryReturn}
                                        onChange={handleSearchReturn}
                                        className="input"
                                        onClick={() => setShowSuggestionsReturn(true)}
                                        style={{paddingLeft: '35px', border: returnInvalid ? '1px solid red' : ''}}
                                        placeholder="Search airport or city"
                                    />
                                    <FontAwesomeIcon icon={faPlane} style={{
                                        position: 'absolute',
                                        left: 10,
                                        top: '40%',
                                        transform: 'translateY(55%)',
                                        zIndex: 1,
                                        color: "rgba(100,116,139,1)"
                                    }}/>
                                </div>
                                {showSuggestionsReturn && suggestionsReturn.length > 0 && (
                                    <div className="clearfix" style={{position: 'relative'}}>
                                        <ul className="airport-option" style={{position: 'absolute', zIndex: 9999999}}>
                                            {suggestionsReturn.map((item, index) => (
                                                <li className="airport-option" key={index}
                                                    onClick={() => handleSuggestionReturnClick(item)}>
                                                    <img src={`/img/flags/${item.country.toLowerCase()}.svg`}
                                                         alt={item.country}
                                                         width="15px"/>&nbsp;
                                                    ({item.airportCode}) {item.name}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="clearfix frow">
                            <div className="input-container">
                                <label className="inputLabel" htmlFor="startDate">Depart</label>
                                <DatePicker
                                    selected={departureDate}
                                    onChange={date => updateReturnDate(date)}
                                    minDate={startDate}
                                    dateFormat="dd/MM/yyyy"
                                    className="input"
                                    customInput={<CustomDatePickerInput/>}
                                />
                            </div>
                            {isChecked.option2 && (
                                <div className="input-container">
                                    <label className="inputLabel" htmlFor="returnDate">Return</label>
                                    <DatePicker
                                        selected={returnDate}
                                        onChange={date => setReturnDate(date)}
                                        minDate={departureDate}
                                        dateFormat="dd/MM/yyyy"
                                        className="input"
                                        customInput={<CustomDatePickerInput/>}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="clearfix frow" style={{textAlign: 'center'}}>
                            {!isSubmitting && (
                                <button onClick={submitForm} className="btn btn-primary"
                                        style={{margin: "auto", fontSize: "16px"}}>Complete Your Booking
                                </button>
                            )}
                            {isSubmitting && (
                                <button style={{
                                    margin: "auto",
                                    maxWidth: "500px",
                                    fontSize: "16px",
                                    border: "0px",
                                    padding: '0px',
                                    height: '50px',
                                    background: 'white',
                                }}>
                                    <img src={`/img/Spinner-1s-200px.gif`} width="50px"/>
                                    <p style={{color: "rgba(100, 116, 139, 1)"}}>searching flights...</p>
                                </button>
                            )}
                            {isError && (
                                <div className="alert alert-error">An error has occurred, please try again</div>
                            )}
                            {noFlightsFound && (
                                <div className="alert alert-info">Flights not found for the selected dates</div>
                            )}
                        </div>
                    </div>
                </div>
            </Container>
        </FlightFormArea>
    );
}

export default FlightForm;
